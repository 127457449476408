import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import agent from '../../repositories/agent'
import { useTranslation } from 'react-i18next'
import { initiateOidcLogin } from '../../utils/oidc/oidcHandler'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '400px',
    margin: '0 auto',
    padding: theme.spacing(4),
  },
  logo: {
    width: 180,
    marginBottom: theme.spacing(6),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F8F9FA',
      '& fieldset': {
        borderColor: '#E9ECEF',
      },
      '&:hover fieldset': {
        borderColor: '#E9ECEF',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#E9ECEF',
      },
      borderRadius: '5px',
      '& input': {
        fontSize: '15px',
        color: 'black',
        fontFamily: 'Arial',
        fontWeight: 400,
      },
      '& fieldset': {
        borderColor: 'rgb(194, 194, 194)',
      },
    },
    '& .MuiFormHelperText-root': {
      minHeight: '1.5em',
      marginTop: theme.spacing(0.5),
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
    backgroundColor: '#E8402C',
    '&:hover': {
      backgroundColor: '#d13a27',
    },
    borderRadius: '5px',
    textTransform: 'none',
    fontSize: '14px',
    boxShadow: 'none',
    fontFamily: 'Arial',
    fontWeight: 400,
  },
}))

const LoginBig = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [loading, setLoading] = useState(false)

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!email) {
      return t('email required')
    }
    if (!emailRegex.test(email)) {
      return t('email invalid')
    }
    return ''
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setEmailError(validateEmail(newEmail))
  }

  const handleLogin = async (e) => {
    e.preventDefault()
    const error = validateEmail(email)
    if (error) {
      setEmailError(error)
      return
    }

    setLoading(true)
    try {
      const { data } = await agent.get(`/v2/auth/openid-settings/${email}`)
      console.log('OpenID settings:', data)

      const { ProviderHint, ProviderHintFound } = data
      if (ProviderHintFound) {
        initiateOidcLogin('login', '/', { kc_idp_hint: ProviderHint })
      } else {
        initiateOidcLogin('login', '/', { login_hint: email })
      }
    } catch (error) {
      console.error('Failed to fetch OpenID settings:', error)
      // This will likely never happen but is needed until partner API is deployed on production
      initiateOidcLogin('login', '/', { login_hint: email })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <img
          src="https://static.citycity.se/agencies/bigtravel.citycity.se/logo.jpg"
          alt="logo"
          style={{ width: '120px', marginBottom: '50px' }}
        />
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={handleEmailChange}
            error={!!emailError}
            helperText={emailError || ' '}
            className={classes.textField}
            placeholder={t('username or email')}
            InputLabelProps={{ shrink: false }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading || !!emailError || !email}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t('login')
            )}
          </Button>
        </form>
      </Box>
    </Box>
  )
}

export default LoginBig
