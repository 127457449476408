import React, { memo, useEffect } from 'react'
import { Box } from '@material-ui/core'
import LoginBlock from '../../components/reusable/loginBlock'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const Login = () => {
  const { onlyOidc } = useSelector((state) => state.other)

  const history = useHistory()

  useEffect(() => {
    if (onlyOidc) {
      history.push('/login-big')
    }
  }, [])

  if (onlyOidc) {
    return null
  }

  return (
    <Box>
      <LoginBlock />
    </Box>
  )
}

export default memo(Login)
