import React, {useCallback, useContext, useEffect, useMemo, useState} from 'react'
import ReactDOM from 'react-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  GoogleMap,
  useLoadScript,
  OverlayView,
  StandaloneSearchBox,
  Polygon,
} from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  setMapCenter,
  setSelectedHotelPrice,
} from '../../store/hotels/hotelsAction'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import RoomIcon from '@material-ui/icons/Room'
import PinDropIcon from '@material-ui/icons/PinDrop'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import Container from '@material-ui/core/Container'
import { getCurrencyCodeBySign } from '../../utils/general'
import isIframed from '../../utils/isIframed'
import avropaZones from '../../constants/avropa_zones.json'
import { HomeRounded, Place, PlaceOutlined, PlaceTwoTone } from '@material-ui/icons'
import { CardMedia } from '@material-ui/core'
import {DrawerContext as HotelSearchContext} from "../../containers/hotels/result/hotelSearchContent";

const API_KEY = 'AIzaSyA1cRh_nRfWORBJpPOL1GU5Re7taLSiQ_E'

const useStyles = makeStyles((theme) => ({
  marker: {
    fontFamily: 'Metropolis',
    fontSize: '14px',
    fontWeight: 600,
    color: '#333333',
    textAlign: 'center',
    padding: '6px 12px 6px 12px',
    borderRadius: '15.5px',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
    backgroundColor: '#ffffff',
  },

  currentMarker: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },

  selectedMarker: {
    color: '#08c5b9',
    backgroundColor: '#333333',
  },

  searchMarker: {
    color: '#333333',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.25)',
  },

  dropIcon: {
    color: theme.palette.primary.main,
    fontSize: '23px',
    position: 'absolute',
    top: '21px',
    left: '21px',
  },

  addressMarker: {
    fontFamily: 'Metropolis',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    backgroundColor: 'black',
    padding: '3px 4px 3px 4px',
    borderRadius: '16px',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
  },

  addressMarkerLogo: {
    backgroundColor: 'white',
    padding: '3px 4px 3px 4px',
    borderRadius: '16px',
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)',
    border: '1px solid #333333',
  },

  companyLogo: {
    height: '20px',
    width: '60px',
    display: 'inline-block',
  },

  hoverBox: {
    position: 'fixed',
    right: '80px',
    bottom: '24px',
    borderRadius: '6px',
    backgroundColor: 'white',
    fontSize: '16px',
    padding: '6px 12px 6px 12px',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.25)',
  },
}))

const mapNumber = (n) => {
  return Number(Number.parseFloat(n).toPrecision(10))
}

const libraries = ['places']

const HotelBackgroundMap = ({
  initialLat,
  initialLng,
  hotels,
  isMiddleStep,
  showMap,
  mapFieldPosition
}) => {
  const { searchFilters } = useContext(HotelSearchContext)
  const { currentHotel } = searchFilters || {}

  const initialCenter = useMemo(
    () => ({
      lat: mapNumber(initialLat),
      lng: mapNumber(initialLng),
    }),
    [initialLat, initialLng]
  )
  const authTypes = ['agent', 'viaAgent']
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedHotel = useSelector((store) => store.hotels.selectedHotel)
  const mapCenter = useSelector((store) => store.hotels.mapCenter)
  const { user, authType, currency, addresses, useLogoForMapAddresses } =
    useSelector((state) => state.auth)

  const companyLogoUrl = user?.companyLogo

  const isTopRow =
    !!user?.isBookingLink ||
    authTypes.includes(authType) ||
    window.location.href.includes('bigtravel.se')

  const currencyCode = getCurrencyCodeBySign(currency)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries,
    // ...otherOptions
  })
  const { searchChecksum } = useSelector((state) => state.hotels)

  // used to refresh component when map projection changed
  const [projChanged, setProjChanged] = useState(0)
  const [map, setMap] = useState(null)
  const [searchBox, setSearchBox] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [searchPosition, setSearchPosition] = useState(null)
  const { t } = useTranslation()
  const { enableZoneFilter, zoneFilteredSearch } = useSelector(
    (state) => state.hotels
  )
  const [hoverZone, setHoverZone] = useState(null)
  const [hoverHotel, setHoverHotel] = useState(null)
  const [hoverAddress, setHoverAddress] = useState(null)

  const isSweden = searchChecksum?.startsWith('SE')

  useEffect(() => {
    let point =
      mapCenter || offsetCenter(searchPosition || initialCenter, 500, 0)

    if (point?.requiresOffset) {
      point = offsetCenter(point, 500, 0)
    }

    if (map && point) {
      if (!isMobile) {
        map.panTo(point)
      } else {
        map.setCenter(point)
      }
    }
    // eslint-disable-next-line
  }, [isMobile, initialCenter, searchPosition, mapCenter, map])

  function offsetCenter(latlng, offsetx, offsety) {
    if (isMobile) return latlng
    if (!map || !map.getProjection()) return

    var scale = Math.pow(2, map.getZoom())

    var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng)
    var pixelOffset = {
      x: offsetx / scale || 0,
      y: offsety / scale || 0,
    }

    var worldCoordinateNewCenter = {
      x: worldCoordinateCenter.x - pixelOffset.x,
      y: worldCoordinateCenter.y + pixelOffset.y,
    }
    const point = map
      .getProjection()
      .fromPointToLatLng(worldCoordinateNewCenter)
    return { lat: point.lat(), lng: point.lng() }
  }

  const onMarkerClick = useCallback(
    (hotelCode) => {
      dispatch(setSelectedHotelPrice(hotelCode))
    },
    [dispatch]
  )

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  const wrapperStyle = {
    position: 'fixed',
    width: 'calc(100vw - 15px)', // Workaround
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  }

  const wrapperStyleMobile = showMap
    ? {
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        top: 0,
        left: 0,
      }
    : {
        position: 'static',
        width: '100vw', // Workaround
        top: 0,
        left: 0,
        right: 0,
        height: '280px',
      }

  const containerStyle = {
    width: '100%',
    height: isIframed ? '100vh' : '100%',
    padding: '0px',
  }

  const zoneMarkers = useMemo(() => {
    if (!enableZoneFilter || !zoneFilteredSearch || !avropaZones) return null

    let visibleZoneNames = [
      ...new Set(
        zoneFilteredSearch.map((hotel) => hotel.avropaData['Ort/Zon'])
      ),
    ]

    let zones = avropaZones.filter((zone) =>
      visibleZoneNames.includes(zone['name'])
    )

    return zones.map((zone) => {
      //      var bounds = new google.maps.LatLngBounds()

      const paths = zone['coords'].split(' ').map((coords) => ({
        lat: mapNumber(coords.split(',')[0]),
        lng: mapNumber(coords.split(',')[1]),
      }))

      const handleMouseOver = () => {
        //        console.log('meow')
        setHoverZone(zone)
      }

      const handleMouseOut = () => {
        //        console.log('out')
        setHoverZone(null)
      }

      const options = {
        fillColor: zone.color || 'lightblue',
        fillOpacity: 0.4,
        strokeColor: 'darkblue',
        strokeOpacity: 1,
        strokeWeight: 1,
        clickable: true,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 1,
      }

      return (
        <Polygon
          key={zone.name}
          paths={paths}
          options={options}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
      )
    })
  }, [enableZoneFilter, zoneFilteredSearch, avropaZones])

  const markers = useMemo(() => {
    const currentListOfHotels =
      isSweden && enableZoneFilter && zoneFilteredSearch
        ? zoneFilteredSearch
        : hotels

    const pixelOffset = (offsetWidth: number, offsetHeight: number) => {
      return {
        x: -(offsetWidth / 2),
        y: -(offsetHeight / 2),
      }
    }

    return currentListOfHotels.map((hotel) => {
      const key = hotel?.hotelCode
      const isSelected = selectedHotel && selectedHotel === key
      return (
        <OverlayView
          key={key}
          position={{
            lat: mapNumber(hotel?.CachedData?.LATITUDE),
            lng: mapNumber(hotel?.CachedData?.LONGITUDE),
          }}
          mapPaneName={
            isSelected
              ? OverlayView.FLOAT_PANE
              : OverlayView.OVERLAY_MOUSE_TARGET
          }
          getPixelPositionOffset={pixelOffset}
        >
          <div
            className={[
              classes.marker,
              isSelected ? classes.selectedMarker : '',
              currentHotel === hotel.hotelCode ? classes.currentMarker : '',
            ].join(' ')}
            onClick={() => onMarkerClick(key)}
            onMouseOver={() => setHoverHotel(hotel)}
            onMouseOut={() => setHoverHotel(null)}
          >
            {hotel.pricePerRoomAndNight}
            {currencyCode}
          </div>
        </OverlayView>
      )
    })
  }, [
    hotels,
    classes.marker,
    classes.selectedMarker,
    onMarkerClick,
    selectedHotel,
    zoneFilteredSearch,
    enableZoneFilter,
  ])

  const addressMarkers = useMemo(
    () =>
      addresses?.map((address) => (
        <OverlayView
          key={address.placeId}
          position={{
            lat: address.location.lat,
            lng: address.location.lng,
          }}
          mapPaneName={OverlayView.FLOAT_PANE}
        >
          {useLogoForMapAddresses ? (
            <div
              className={classes.addressMarkerLogo}
              onMouseOver={() => setHoverAddress(address)}
              onMouseOut={() => setHoverAddress(null)}
            >
              <CardMedia
                data-id="company-logo"
                image={companyLogoUrl}
                className={classes.companyLogo}
                display="inline"
                style={{}}
              />
            </div>
          ) : (
            <div
              className={classes.addressMarker}
              onMouseOver={() => setHoverAddress(address)}
              onMouseOut={() => setHoverAddress(null)}
            >
              <HomeRounded></HomeRounded>
            </div>
          )}
        </OverlayView>
      )),
    [addresses, user]
  )

  useEffect(() => {
    dispatch(setMapCenter(null))
    setSearchPosition(null)
    // eslint-disable-next-line
  }, [initialCenter?.lat, initialCenter?.lng])

  const onSearchBoxLoad = (ref) => setSearchBox(ref)
  const onPlacesChanged = () => {
    if (searchBox.getPlaces().length === 1) {
      let place = searchBox.getPlaces()[0]
      dispatch(setMapCenter(null))
      setSearchPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      })
    }
  }

  const onDragEnd = () => {
    if (map && map.getCenter()) {
      dispatch(
        setMapCenter({ lat: map.getCenter().lat(), lng: map.getCenter().lng() })
      )
    }
  }

  const currInitCenter = mapCenter || offsetCenter(initialCenter, 500, 0)

  const memorizedCenter = useMemo(
    () => currInitCenter,
    [currInitCenter?.lat, currInitCenter?.lng]
  )
  
  const fieldPos = !!mapFieldPosition && typeof (mapFieldPosition) === 'object' ? {
    ...mapFieldPosition
  } : {
    right: '64px',
    top: !!isTopRow ? '63px' : '8px',
  }
  return isLoaded ? (
    <div style={isMobile ? wrapperStyleMobile : wrapperStyle}>
      <GoogleMap
        clickableIcons={false}
        options={{
          disableDefaultUI: true,
          gestureHandling: isMobile && showMap ? 'greedy' : 'cooperative',
          zoomControl: !isMobile,
          scrollwheel: false,
        }}
        mapContainerStyle={containerStyle}
        center={memorizedCenter}
        zoom={isMobile ? 16 : 14}
        style={{ position: 'fixed' }}
        onLoad={onLoad}
        onProjectionChanged={() => setProjChanged(projChanged + 1)}
        onUnmount={onUnmount}
        onDragEnd={onDragEnd}
      >
        {!isMobile &&
          isMiddleStep &&
          ReactDOM.createPortal(
            <Container
              maxWidth="lg"
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 100,
              }}
            >
              <StandaloneSearchBox
                bounds={map ? map.getBounds() : null}
                onLoad={onSearchBoxLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <Box
                  p={1.5}
                  style={{
                    fontFamily: theme.typography.fontFamily,
                    backgroundColor: 'transparent',
                    borderRadius: theme.spacing(1.5),
                    position: 'absolute',
                    zIndex: 0,
                    ...fieldPos
                  }}
                >
                  <PinDropIcon className={classes.dropIcon} />
                  <input
                    type="text"
                    placeholder={isMobile || t('where meeting')}
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid ${theme.palette.divider}`,
                      width: '280px',
                      height: `42px`,
                      padding: `0 12px 0 35px`,
                      borderRadius: `3px`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipsis`,
                    }}
                  />
                </Box>
              </StandaloneSearchBox>
            </Container>,
            document.body
          )}
        {!isMobile && !isMiddleStep && (
          <Container
            maxWidth="lg"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 100,
            }}
          >
            <StandaloneSearchBox
              bounds={map ? map.getBounds() : null}
              onLoad={onSearchBoxLoad}
              onPlacesChanged={onPlacesChanged}
            >
              <Box
                p={1.5}
                style={{
                  fontFamily: theme.typography.fontFamily,
                  backgroundColor: theme.palette.dimWhite,
                  borderRadius: theme.spacing(1.5),
                  position: 'absolute',
                  right: '0',
                  top: !!isTopRow ? '280px' : '210px',
                }}
              >
                <PinDropIcon className={classes.dropIcon} />
                <input
                  type="text"
                  placeholder={isMobile || t('where meeting')}
                  style={{
                    boxSizing: `border-box`,
                    border: `1px solid transparent`,
                    width: '330px',
                    height: `42px`,
                    padding: `0 12px 0 35px`,
                    borderRadius: `3px`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipsis`,
                  }}
                />
              </Box>
            </StandaloneSearchBox>
          </Container>
        )}

        {zoneMarkers}

        {markers}

        {addressMarkers}

        {searchPosition && (
          <OverlayView
            position={searchPosition}
            mapPaneName={OverlayView.FLOAT_PANE}
          >
            <RoomIcon style={{ fontSize: '48px', color: '#333333' }} />
          </OverlayView>
        )}

        {!searchPosition && (
          <OverlayView
            // position={offsetCenter(initialCenter, 25, 25)}
            position={initialCenter}
            mapPaneName={OverlayView.FLOAT_PANE}
          >
            <Place style={{ fontSize: '50px', color: '#333333' }} />
          </OverlayView>
        )}

      </GoogleMap>

      {!isMobile && (hoverHotel || hoverZone || hoverAddress) && (
        <div className={classes.hoverBox}>
          {hoverAddress && (
            <span style={{ fontWeight: 600 }}>{hoverAddress.name}</span>
          )}
          {hoverHotel && (
            <span style={{ fontWeight: 600 }}>
              {hoverHotel.title}
              {enableZoneFilter && hoverZone ? ', ' : ''}
            </span>
          )}
          {enableZoneFilter && hoverZone && (
            <span style={{ fontWeight: 400 }}>
              {hoverZone.label || hoverZone.name}
            </span>
          )}
          {enableZoneFilter && hoverHotel?.avropaData && (
            <div style={{ fontSize: '12px', fontWeight: 400 }}>
              {t('category')}{' '}
              <span style={{ fontWeight: 600 }}>
                {hoverHotel.avropaData['Kategori']}
              </span>{' '}
              {t('rank')}{' '}
              <span style={{ fontWeight: 600 }}>
                {hoverHotel.avropaData['Rank']}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    ''
  )
}
export default HotelBackgroundMap
